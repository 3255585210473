<template>
  <div class="account-table">
    <el-table
      :data="tableData"
      :height="tableHeight"
      v-loading="loading"
      style="width: 100%"
      @row-click="rowSelected"
      ref="table-account"
    >
      <el-table-column
        v-for="(column, index) in columnFields"
        :key="index"
        :label="column.label"
        :prop="column.field"
        :fixed="column.fixed"
        :min-width="column.minWidth"
      >
        <template slot-scope="scope">
          <div v-if="column.field === 'company_name'">
            {{ scope.row.company_name }}
          </div>
          <div v-if="column.field === 'account_name'">
            {{ scope.row.account_name }}
          </div>
          <div v-if="column.field === 'address'">
            {{ scope.row.company_address }}
          </div>
          <div v-if="column.field === 'modes'">
            {{
              accountAvailableModes({
                exp: scope.row.can_dispatch_exp,
                flatbet: scope.row.can_dispatch_flatbed,
                ftl: scope.row.can_dispatch_ftl,
                insurance: scope.row.can_dispatch_insurance,
                ltl: scope.row.can_dispatch_ltl,
                reefer: scope.row.can_dispatch_reefer,
                drayage: scope.row.can_dispatch_drayage,
              })
            }}
          </div>
          <div v-if="column.field === 'network_id'">
            <i class="ion ion-checkmark-outline ion-true" v-if="scope.row.network_id"></i>
            <i class="ion ion-close-outline ion-false" v-else></i>
          </div>
          <div v-if="column.field === 'branding'">
            <i class="ion ion-checkmark-outline ion-true" v-if="scope.row.is_branded"></i>
            <i class="ion ion-close-outline ion-false" v-else></i>
          </div>
          <div v-if="column.field === 'date'">
            {{ scope.row.created_at | dateFormat }}
          </div>
          <div v-if="column.field === 'children_account'">
            {{ listAccountsChildren(scope.row.children_accounts) }}
          </div>
          <div v-if="column.field === 'tools'" :key="reRenderKey">
            <AccountTools :account="scope.row" />
          </div>
        </template>
      </el-table-column>
    </el-table>
    <CustomModal v-model="showAccountModal" modalTitle="Account" size="lg" centered>
      <template #modal-content>
        <AccountDetailModel :accountUsers="accountUsers" :account="selectedAccount" />
      </template>
    </CustomModal>
  </div>
</template>

<script>
import OnResize from "@/Extend/onResize";
import AccountTools from "./AccountTableTools.vue";
import AccountDetailModel from "./AccountDetailModel.vue";
import CustomModal from "../../../components/CustomModal.vue";

export default {
  name: "AccountTable",
  mixins: [OnResize],
  props: {
    columnFields: Array,
    tableData: Array,
    loading: Boolean,
    allDataHasBeenLoaded: Boolean,
    updating: Boolean,
    reRenderKey: Number,
  },
  components: {
    AccountTools,
    AccountDetailModel,
    CustomModal,
  },
  data() {
    return {
      tableHeight: null,
      showAccountModal: false,
      selectedAccount: {},
      tableContainer: null,
      tableContainerHeight: null,
      accountUsers: null,
    };
  },
  watch: {
    screenWidth() {
      const clientHeight = window.innerHeight;
      this.tableHeight = clientHeight - 200;
    },
  },
  async mounted() {
    await this.sleep(0);
    this.tableContainer = document.querySelector(".el-table__body-wrapper");
    this.tableContainerHeight = this.tableContainer.clientHeight;
    this.tableContainer.addEventListener("scroll", this.infiniteScrollHandler);
    this.rowsContainer = document.querySelector(".el-table__body");
  },
  computed: {
    companyAddress() {
      /* eslint-disable */
      const address = `${this.account.company_address} - ${this.account.city}, ${this.account.state_code}, ${this.account.zip_code}`;
      return address;
    },
  },
  methods: {
    infiniteScrollHandler() {
      if (this.allDataHasBeenLoaded || this.updating) {
        return;
      }
      const elementTableHeight = this.rowsContainer.clientHeight;
      const currentScrollPosition = this.tableContainer.scrollTop;
      if (currentScrollPosition + this.tableContainerHeight < elementTableHeight - 20) {
        return;
      }
      this.tableContainer.scrollTop = currentScrollPosition - 30;
      this.$emit("loadMoreItems");
    },
    async sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    accountAvailableModes({ exp, flatbet, ftl, insurance, ltl, reefer }) {
      const modes = [];
      if (ftl) modes.push("FTL");
      if (ltl) modes.push("LTL-Parcel");
      if (reefer) modes.push("Reefer");
      if (flatbet) modes.push("Flatbed");
      if (insurance) modes.push("Insurance");
      if (exp) modes.push("Exp");
      return modes.join(", ") || "";
    },
    listAccountsChildren(accounts) {
      if (!accounts) {
        return "";
      }
      const list = [];
      accounts.forEach((element) => {
        list.push(element.children_name || element.name);
      });
      return list.join(", ") || "";
    },
    formatAddressInfo() {
      return "Address !";
    },
    async rowSelected(accountInfo) {
      this.selectedAccount = accountInfo;
      this.accountUsers = await this.$store.dispatch(
        "account/getAllUsersByAccountId",
        accountInfo.id
      );
      this.showAccountModal = true;
    },
    disableScroll() {
      this.tableContainer.style.overflowY = "hidden";
    },
    enableScroll() {
      this.tableContainer.style.overflowY = "auto";
    },
    reRenderTable() {
      this.$nextTick(() => {
        this.$refs['table-account'].doLayout();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.account-table {
  @include table-design-book(3);
  width: 97%;
  margin: auto;
}
::v-deep .el-table {
  background: transparent;
  tr {
    background-color: transparent;
    cursor: pointer;
    .gutter {
      display: none !important;
    }
  }
  &__row {
    .el-table__cell {
      background: white;
      text-align: center;
      border-bottom: 1px solid $color-border-container;
      word-break: normal;
    }
  }
}
.ion-true {
  color: #0c5d19;
  font-size: 1rem;
}
.ion-false {
  color: #e11c0f;
  font-size: 1rem;
}
::v-deep {
  .el-table__header {
    th:nth-child(1) {
      display: flex;
      width: 99%;
      margin: auto;
      top: 2px;
      padding-bottom: 8px;
      font-weight: 600;
      font-size: 11px;
      color: $color-primary-company;
      text-align: center;
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
      background: #efefef;
      .cell {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 17px;
      }
    }
  }
}
</style>
